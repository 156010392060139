$(document).ready(function() {

    // Variable
    var primaryMenu = $('.nav-primary .nav');
    var menuIcon = $('.menu__icon')
    var primaryOverlayTouch = $('.nav-primary__overlay');
    var touchEvents = $('.touchevents');
    var body = $('body')

    // Variable: primaryMenu
    // Add icon angle down to element with dropdown menu
    primaryMenu.find('> li.menu-item-has-children > a').append('<i class="fal fa-angle-down"></i>');
    primaryMenu.find('> li ul.sub-menu > li.menu-item-has-children > a').append('<i class="fal fa-angle-right"></i>');

    // Add class sub-menu1, sub-menu2 and submenu-3 to first, second and third dropdown menu
    primaryMenu.find('> li > ul.sub-menu').addClass('sub-menu1');
    primaryMenu.find('> li > ul.sub-menu > li > ul.sub-menu').addClass('sub-menu2');
    primaryMenu.find('> li > ul.sub-menu > li > ul.sub-menu li > ul.sub-menu').addClass('sub-menu3');

    // Variable: touchEvents
    touchEvents.find('.nav-primary .nav .menu-item-has-children').append('<div class="menu-item-has-children__overlay"></div>');
    touchEvents.find('.menu-item-has-children__overlay').on('click', function(){
        $(this).parent('.menu-item-has-children').addClass('menu-item-has-children__open');
    });

    // Variable: menuIcon
    menuIcon.on('click', function(){
        body.toggleClass('menu__open');
        primaryOverlayTouch.toggleClass('nav-primary__overlay--active');
        $('.menu__word__open').toggle();
        $('.menu__word__close').toggle();
    });

    $(window).scroll(function() {
        if ($(this).scrollTop() >= 500) {
            $('.header').addClass('header--fixed');
            $('body').addClass('body--open');
        } else {
            $('.header').removeClass('header--fixed');
            $('body').removeClass('body--open');
        }
    });

    // Product category
    
    touchEvents.find('.product__subcategorys').parent('.product__menu__inner > ul > li').addClass('has__children');
    touchEvents.find('.product__menu__inner > ul > li.has__children').append('<div class="productcategory__item__overlay"></div>');
    touchEvents.find('.productcategory__item__overlay').on('click', function(){
        $(this).parent('.product__menu__inner > ul > li').addClass('product__subcategorys--active');
    });

    touchEvents.find('.back__button').on('click', function(){
        $(this).parent().parent().parent('.product__menu__inner > ul > li').removeClass('product__subcategorys--active');
    });
});
